import { combineReducers } from 'redux';
import requests from './requests';
import statuses from './statuses';
import app from './app';
import data from './data';

export default combineReducers({
  requests,
  statuses,
  app,
  data,
});
