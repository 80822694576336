import {
  SET_DRAWER_ITEM,
  SET_DRAWER_ID,
} from '../action-constants';

const initialState = {
  drawerId: null,
  drawerItem: null,
};

const setDrawerId = (state, { payload }) => ({
  ...state,
  drawerId: payload,
});

const setDrawerItem = (state, { payload }) => ({
  ...state,
  drawerItem: payload,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DRAWER_ID: {
      return setDrawerId(state, action);
    }
    case SET_DRAWER_ITEM: {
      return setDrawerItem(state, action);
    }
    default:
      return state;
  }
};
